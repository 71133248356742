
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { StoreMixin } from '@mixin/store';
import { app } from '@config';
import { handle429 } from '@util/util.ts';
import axios from 'axios';
import Map from '@part/elements/Map.vue';
import CardDefault from '@part/elements/CardDefault.vue';
import Slider from '../../../fruitpackage/components/Slider.vue';
import ArrowCircleUpSvg from '@svg/ArrowCircleUp.vue';
import ArrowCircleDownSvg from '@svg/ArrowCircleDown.vue';
import ArrowForwardSvg from '@svg/ArrowForward.vue';
import HeartBrokenSvg from '@svg/HeartBroken.vue';
import HighlightedAmbassadorsBlock from '@part/blocks/HighlightedAmbassadorsBlock.vue';
import LogoutDoorSvg from '@svg/LogoutDoor.vue';
import TrashSvg from '@svg/Trash.vue';
import TicketSvg from '@svg/Ticket.vue';
import ActiveStartLocationSvg from '@svg/ActiveStartLocation.vue';
import BackgroundStrokesSvg from '@svg/BackgroundStrokes.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  data() {
    return {
      menuVisible: false,
      pageData: null,
      mapBulletList: null,
      museumOrder: [],
      museumCoords: [],
      museums: [],
      editingRouteUp: false,
      editingRouteDown: false,
      ambassadors: [],
      museumToAnim: 0,
      mapVisible: false,
      removingId: null
    };
  },
  mixins: [StoreMixin],
  components: {
    HighlightedAmbassadorsBlock,
    Map,
    CardDefault,
    Slider,
    ArrowCircleUpSvg,
    ArrowCircleDownSvg,
    TrashSvg,
    LogoutDoorSvg,
    HeartBrokenSvg,
    ArrowForwardSvg,
    TicketSvg,
    BackgroundStrokesSvg,
    ActiveStartLocationSvg
  },
  props: {
    setOverlayStatus: {
      type: Function,
      required: false,
    }
  },
  computed: {
    ...mapState(['acceptingWarning', 'warningId']),
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  },
  watch: {
    acceptingWarning: function () {
      if (this.removingId == this.warningId && this.acceptingWarning) {
        this.triggerAcceptWarning(false);
        this.removeMuseum(this.removingId);
      }
    },
  },
  methods: {
    ...mapMutations(['toggleWarning', 'setWarningId', 'triggerAcceptWarning']),
    randomlyPickPrograms() {
      if (!this.isLoggedIn) {
        this.$router.push('/login');
      } else {
        const instance = axios.create({
          baseURL: app.api.auth,
          timeout: 2500,
          headers: {
            Authorization: 'Bearer ' + this.user.token
          }
        });

        instance
          .get('/route/for-me')
          .then(response => {
            this.$store.dispatch('setToastMessage', `Wij hebben "${response.data.event}" voor je toegevoegd!`);
            this.$store.dispatch('setToastButton', '');

            this.getData();
            return response.data;
          }).catch(error => {
            handle429(this, error);
          });
      }
    },
    arrayMove(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
    moveUp(i) {
      if (i == 0) {
        return;
      }
      this.museumOrder = this.arrayMove(this.museumOrder, i, i - 1);
      this.reorderInApi(i, 'up');
    },
    moveDown(i) {
      if (i == this.museumOrder.length - 1) {
        return;
      }
      this.museumOrder = this.arrayMove(this.museumOrder, i, i + 1);
      this.reorderInApi(i, 'down');
    },
    getData(i, direction) {

      // animation
      i && (this.museumToAnim = i);
      if (direction) {
        direction == 'up' && (this.editingRouteUp = true);
        direction == 'down' && (this.editingRouteDown = true);
      }

      const dynamicInstance = axios.create({
        baseURL: app.api.auth,
        timeout: 2500,
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      });

      const instance = axios.create({
        baseURL: app.api.api,
        timeout: 2500,
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      });

      dynamicInstance.get('/route').then(response => {
        setTimeout(() => {
          this.editingRouteUp = false;
          this.editingRouteDown = false;
        }, 200);

        this.pageData = response.data;

        if (response.data.data.museums) {
          this.museumOrder = [];
          this.museumCoords = [];

          response.data.data.museums.forEach(museum => {
            this.museumOrder.push(museum.id);
            this.museumCoords.push({
              name: museum.name,
              position: museum.coords,
              address: museum.address
            });
          });
        }

        instance.get('/feed/ambassadors').then(resp => {
          this.ambassadors = resp.data.data;
          this.mapVisible = false;
          this.mapVisible = true;
        });

        return response.data;
      });
    },
    reorderFrontend(i, direction) {
      i && (this.museumToAnim = i);
      direction == 'up' && (this.editingRouteUp = true);
      direction == 'down' && (this.editingRouteDown = true);

      setTimeout(() => {
        // change order in page data (front end only)
        if (direction == 'up') {
          if (i == 0) {
            return;
          }
          this.pageData.data.museums = this.arrayMove(this.pageData.data.museums, i, i - 1);
        } else {
          if (i == this.pageData.data.museums.length - 1) {
            return;
          }
          this.pageData.data.museums = this.arrayMove(this.pageData.data.museums, i, i + 1);
        }

        this.editingRouteUp = false;
        this.editingRouteDown = false;
      }, 200);
    },
    reorderInApi(i, direction) {
      axios
        .post(
          `${app.api.auth}/route`,
          {
            order: this.museumOrder
          },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + this.user.token
            }
          }
        )
        .then(response => {
          // do nothing
          this.reorderFrontend(i, direction);
        })
        .catch(error => {
          console.log(`🔥${error}🔥`);
          handle429(this, error);
        });
    },
    askRemoveMuseum(id) {
      this.removingId = id;
      this.setWarningId(id);
      this.toggleWarning(true);
    },
    removeMuseum(id) {
      // remove in front end
      let indOf = this.pageData.data.museums.findIndex(i => i.id === id);
      indOf >= 0 && this.pageData.data.museums.splice(indOf, 1);

      // remove in back end
      const instance = axios.create({
        baseURL: app.api.auth,
        timeout: 2500,
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      });

      instance.get(`/route/remove-museum/${id} `).then(response => {
        // do nothing
        return response.data;
      });
    }
  },
  mounted() {
    this.getData();
    axios
      .get(`${app.api.api}/page/${this.$route.meta.id}`)
      .then(response => {
        this.pageBlocks = response.data;
        this.mapBulletList = response.data.data.tips.find((tip) => tip.block === 'BulletsBlock');
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });

    // ///// QUICKFIX /////////
    document.title = 'Mijn Nacht | Museumnacht Amsterdam';
    // //////////////////////////

  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class MyNight extends Vue { }
